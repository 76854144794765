/*! 2025-4-2 20:17:05 */
[data-v-53ad7114]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.upload_file[data-v-53ad7114]{max-height:70px;overflow-y:auto}.upload_file .file_list .file_line[data-v-53ad7114]{width:360px;height:32px;background:#f5f7fa;border-radius:3px;border:1px solid #ebeef5;line-height:32px;font-size:12px;color:#606266;position:relative;display:flex;justify-content:flex-start;align-items:center;margin-top:5px}.upload_file .file_list .file_line span[data-v-53ad7114]{margin-right:6px}.upload_file .file_list .file_line .icon_file[data-v-53ad7114]{font-size:16px;color:#fa6400;margin:0 10px}.upload_file .file_list .file_line .upload-file-color[data-v-53ad7114]{display:inline-block;width:160px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;text-align:left}.upload_file .file_list .file_line .size[data-v-53ad7114]{width:55px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.upload_file .file_list .file_line .icon_success[data-v-53ad7114]{color:#1989fa}.upload_file .file_list .file_line .icon_del[data-v-53ad7114]{font-size:16px;position:absolute;right:5px;top:6px;cursor:pointer}.el-upload__tip[data-v-53ad7114]{overflow:hidden;white-space:nowrap;text-overflow:ellipsis;margin-top:4px}


.require[data-v-798c5b36] {
  color: red;
}


.calculated-date-picker[data-v-7735a135] {
  display: flex;
  align-items: center;
}
.el-button[data-v-7735a135] {
  margin-left: 4px;
}


.require[data-v-66e1bd1c] {
  color: red;
}


/*# sourceMappingURL=chunk-5973e874.80cd9e42.css.map*/